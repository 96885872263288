import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SOUNDS, useAudio } from '../hooks/useAudio';
import { POSITION } from '../hooks/useGame';
import { User } from '../../../../shared/models';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontFamily: 'Luckiest Guy',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '26px',
        letterSpacing: '0.46px',
        textTransform: 'uppercase',
        color: '#575757',

        height: '68px',
        position: 'absolute',

        transitionProperty: 'left, top, transform',
        transitionDuration: '300ms', // Duration per cell movement
        transform: 'scale(1)',

        '& img': {
            height: '100%',
        },
    },
}));

type PlayerProps = {
    positionInCell: number;
    playerTurn: number;
    playerPosition: number; // Target position
    user: User;
    color: string;
};

export const Player = (player: PlayerProps) => {
    const classes = useStyles();
    const tileCountX = 8;
    const moveAudio = useAudio(SOUNDS.MOVE, {});
    const [currentPosition, setCurrentPosition] = useState<number>(player.playerPosition); // Current cell position

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        const moveStepByStep = () => {
            timer = setInterval(() => {
                setCurrentPosition((prev) => {
                    if (prev < player.playerPosition) {
                        moveAudio.play();
                        return prev + 1; // Move forward
                    }
                    if (prev > player.playerPosition) {
                        moveAudio.play();
                        return prev - 1; // Move backward
                    }
                    clearInterval(timer!); // Stop when target is reached
                    return prev;
                });
            }, 300); // Adjust speed per cell
        };

        // Start movement if currentPosition differs from target
        if (currentPosition !== player.playerPosition) {
            clearInterval(timer!); // Clear previous interval to avoid overlap
            moveStepByStep();
        }

        return () => {
            clearInterval(timer!); // Cleanup interval on unmount or update
        };
    }, [player.playerPosition, currentPosition, moveAudio]);

    const indexToPosition = (toCell: number): [number, number] => {
        if (toCell !== -1) {
            const cell = POSITION[toCell];
            const x = Math.floor((toCell + 1 !== 81 ? cell : -1) / tileCountX);
            const y = Math.floor(((toCell + 1) - (toCell + 1 !== 81 ? 1 : 2)) / 10);
            return [x, y];
        }
        return [-1, 0];
    };

    const positionToPixelsX = (position: number) => {
        let offset = player.positionInCell !== 0 && player.positionInCell !== 1 && player.positionInCell !== 2 ? 8.6 / 2 : 0;
        if (position === -1) {
            return `calc(100% - 50px + ${offset}% + calc(${position} * 8.4%) + 1%)`;
        } else {
            return `calc(100% - 50px + ${offset}% - calc(${tileCountX - position + 1} * 8.4%) - 16%)`;
        }
    };

    const positionToPixelsY = (position: number) => {
        let offsetInCell = player.positionInCell !== 0 && player.positionInCell !== 3 ? (player.positionInCell % 3) * 4 : 0;
        return `calc(10px + 0.4% + ${offsetInCell}% + calc(${position} * 12.4%))`;
    };

    const positionToZIndex = (position: number) => {
        if (position === -1) return 12;
        return position + 1;
    };

    const [imgUrl, setImgUrl] = useState<string>('');
    useEffect(() => {
        let url = '/static/images/player_' + player.color + '.png';
        setImgUrl(url);
    }, [player.color]);

    const style = {
        top: positionToPixelsX(indexToPosition(currentPosition)[0]),
        left: positionToPixelsY(indexToPosition(currentPosition)[1]),
        zIndex: positionToZIndex(indexToPosition(currentPosition)[0]),
    };

    return (
        <div className={classes.root} style={style}>
            {player.user && <img src={imgUrl} alt="" />}
        </div>
    );
};

export default Player;
