import { Topic } from "../../../../shared/models";
import { PlayerMeta } from "../models/Player.model";
import { RemainingQuestion } from "../models/QuestionModal.model";

export type State = {
  diceRoll?: number;
  roomCode: string,
  gameId: number,
  topic: Topic,
  gameState: string,
  players: {
    [turn: number]: PlayerMeta
  },
  currentTurnPlayer: number, // current turn [0, activePlayers-1]
  isMyTurn: boolean, // if it's user's turn
  currentRemainingQuestion: RemainingQuestion | null,
  multicolorQueue: RemainingQuestion[],
  blackholeQueue: RemainingQuestion[],
  startTimestampState: Date | null,
  endTimestampState: Date | null,
}

export const initialState: State = {
  roomCode: '',
  gameId: -1,
  topic: {} as Topic,
  gameState: "NONE",
  players: {},
  currentTurnPlayer: -1,
  isMyTurn: false,
  multicolorQueue: [],
  blackholeQueue: [],
  currentRemainingQuestion: null,
  startTimestampState: null,
  endTimestampState: null,
  diceRoll: 0
}

type Action = 
  | { type: "INIT_REDUCER"; state: State; roomPlayers: PlayerMeta[] }
  | { type: "ON_UNMOUNT_COMPONENT"}
  ;

export const GameReducer = (state: State, action: Action) => {
  let aux, id;
  switch (action.type) {
    case "INIT_REDUCER":
      // updates players after state updated
      for (let i = 0; i < action.roomPlayers.length; ++i) {
        aux = Object.keys(action.state.players).map((key: any, _) => {
          return action.state.players[key]
        })
        const sameCellPlayers1 = aux.filter(player => player.playerPosition === action.roomPlayers[i].playerPosition && player.user.id !== action.roomPlayers[i].user.id);
        let availableSpots = [0,1,2,3,4,5];
        for (let i = 0; i < sameCellPlayers1.length; ++i) {
          if (availableSpots.includes(sameCellPlayers1[i].positionInCell)) availableSpots.splice(availableSpots.indexOf(sameCellPlayers1[i].positionInCell), 1);
        }
        action.roomPlayers[i].positionInCell = availableSpots[0];
        action.state.players = {
          ...action.state.players,
          [action.roomPlayers[i].playerTurn]: action.roomPlayers[i]
        }
      }
      id = parseInt(localStorage.getItem('userId') || '') || -1;
      action.state.isMyTurn = action.state.players[action.state.currentTurnPlayer]?.user.id === id;
      return action.state;
    case "ON_UNMOUNT_COMPONENT": 
      return {
        roomCode: '',
        gameId: -1,
        topic: {} as Topic,
        gameState: "NONE",
        players: {},
        currentTurnPlayer: -1,
        isMyTurn: false,
        multicolorQueue: [],
        blackholeQueue: [],
        currentRemainingQuestion: null,
        startTimestampState: null,
        endTimestampState: null,
      }
    default: return state;
  }
}
